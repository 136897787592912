import _ from 'underscore'
import ApplicationController from 'controllers/application_controller'

export default class extends ApplicationController {

  static targets = ['input']

  static values  = {
    itemsPerGroup:    { type: Number, default: 4               },
    attributeAsLabel: { type: String, default: ''              },
    dataType:         { type: String, default: ''              },
    className:        { type: String, default: 'select-scroll' }
  }

  get inputOptions() {
    return this.inputTarget.querySelectorAll('option[value]')
  }

  get scroller() {
    if (this._scroller) {
      return this._scroller
    } else if (this.hasInputTarget && this.inputTarget.nextElementSibling && this.inputTarget.nextElementSibling.classList.contains(this.classNameValue)) {
      return this.inputTarget.nextElementSibling
    } else {
      return this.element.querySelector(this.classNameValue)
    }
  }

  set scroller(newScroller) {
    this._scroller = newScroller
  }

  get prevButtonHTML() {
    return `<button type="button" class="${this.classNameValue}-prev" disabled
             data-action="${this.identifier}#showPrevGroup">&larr;</button>`
  }

  get nextButtonHTML() {
    return `<button type="button" class="${this.classNameValue}-next" disabled
             data-action="${this.identifier}#showNextGroup">&rarr;</button>`
  }

  get prevButton() {
    return this.scroller.querySelector(`.${this.classNameValue}-prev`)
  }

  get nextButton() {
    return this.scroller.querySelector(`.${this.classNameValue}-next`)
  }

  get currentGroup() {
    return this.scroller.querySelector(`.${this.classNameValue}-group.current`)
  }

  connect() {
    _.defer(() => this.initializeSelectScroll())
  }

  initializeSelectScroll() {
    if (!this.scroller  && this.hasInputTarget) {
      let scroller = document.createElement('div')
      scroller.className = `${this.classNameValue} ${this.classNameValue}-${this.itemsPerGroupValue}-items-per-group`
      this.inputTarget.after(scroller)

      this.scroller = scroller
    }

    if (this.scroller) {
      this.refreshItems()
      this.element.classList.add(`${this.classNameValue}-enabled`)
    }
  }

  selectValue(event) {
    let item = event.target
    if (!item.classList.contains(`${this.classNameValue}-value`)) {
      item = item.closest(`.${this.classNameValue}-value`)
    }

    let oldOption = _.find(this.inputOptions, (option) => option.selected)
    if (oldOption) { oldOption.selected = false }

    let newOption = this.inputTarget.querySelector(`option[value='${event.params.value}']`)
    newOption.selected = true

    let oldItem = this.scroller.querySelector('.selected')
    if (oldItem) { oldItem.classList.remove('selected') }

    item.classList.add('selected')
  }

  showNextGroup (event) {
    let selected = this.scroller.querySelector(`.${this.classNameValue}-group.current`)
    let next     = selected.nextElementSibling

    if (selected && next) {
      selected.classList.remove('current')
      next.classList.add('current')

      this.refreshNavigation()
    }
  }

  showPrevGroup (event) {
    let selected = this.scroller.querySelector(`.${this.classNameValue}-group.current`)
    let previous = selected.previousElementSibling

    if (selected && previous) {
      selected.classList.remove('current')
      previous.classList.add('current')

      this.refreshNavigation()
    }
  }

  showDefaultGroup() {
    let selectedItem = this.scroller.querySelector(`.${this.classNameValue}-value.selected`)
    if (selectedItem) {
      let group = selectedItem.closest(`.${this.classNameValue}-group`)
      if (group) { group.classList.add('current') }
    } else {
      let group = this.scroller.querySelectorAll(`.${this.classNameValue}-group`)[0]
      if (group) { group.classList.add('current') }
    }

    this.refreshNavigation()
  }

  refreshNavigation() {
    let group = this.currentGroup
    if (group) {
      if (this.prevButton) { this.prevButton.disabled = (group.parentNode.firstChild == group) }
      if (this.nextButton) { this.nextButton.disabled = (group.parentNode.lastChild  == group) }
    } else {
      if (this.prevButton) { this.prevButton.disabled = true }
      if (this.nextButton) { this.nextButton.disabled = true }
    }
  }

  refreshItems() {
    let scroller = this.scroller
    if (scroller) {
      this.morph(scroller, `<div class="${this.classNameValue}-options"></div>`)
      let wrapper = scroller.querySelector(`.${this.classNameValue}-options`)

      _.filter(
        this.inputOptions, (option) => option.value.length >= 1
      ).forEach(
        (option, index) => this.createItem(wrapper, option, index)
      )

      let groups = wrapper.querySelectorAll(`.${this.classNameValue}-group`)
      if (groups.length > 1) {
        scroller.classList.add(`${this.classNameValue}-scrollable`)
        this.morph(scroller, `
          ${this.prevButtonHTML}
          ${scroller.innerHTML}
          ${this.nextButtonHTML}
        `)
      } else {
        scroller.classList.remove(`${this.classNameValue}-scrollable`)
      }

      this.showDefaultGroup()
    }
  }

  listItemHTML(option) {
    let value = option.value
    let label = option.innerText

    if (this.attributeAsLabelValue.length) {
      label = option.getAttribute(this.attributeAsLabelValue)
    }

    let selectedClass = (option.selected ? 'selected' : '')
    let disabledClass = (option.disabled ? 'select-scroll-value-disabled' : '')
    let action        = (option.disabled ? '' : `data-action="click->${this.identifier}#selectValue"`)

    return `
      <li class="${this.classNameValue}-value ${selectedClass} ${disabledClass}"
          ${action}
          data-${this.identifier}-value-param="${value}" >
        <span>${label}</span>
      </li>
    `
  }

  createItem(wrapper, option, index) {
    let group = Math.floor(index / this.itemsPerGroupValue)
    let list  = wrapper.querySelectorAll(`.${this.classNameValue}-group`)[group]

    if (!list) {
      list = document.createElement('ol')
      list.className = `${this.classNameValue}-group`
      wrapper.appendChild(list)
    }

    if (option.value && option.value.length) {
      this.morph(list, `${list.innerHTML}${this.listItemHTML(option)}`)
    }
  }
}
